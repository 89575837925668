<template>
  <div class="listdetails">
    <div style="display: flex;align-items: center;justify-content: flex-start;">
      <span style="font-size: 14px;color: #999;">您的位置：</span>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/newsinformation' }" v-if="activeName=='医院新闻'||activeName=='科室风采'||activeName=='媒体报道'">新闻动态</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/newsinformation?activeName='+activeName+'&tabindex=0' }"  v-if="activeName=='医院新闻'">{{activeName}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/newsinformation?activeName='+activeName+'&tabindex=1' }"  v-if="activeName=='科室风采'">{{activeName}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/newsinformation?activeName='+activeName+'&tabindex=2' }"  v-if="activeName=='科室风采'||activeName=='媒体报道'">{{activeName}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/research'}"  v-if="activeName=='教学科研'">教学科研</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/healthknowledge?activeName='+activeName+'&tabindex=0' }" v-if="activeName=='口腔科普'">{{activeName}}</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 内容 -->
    <div class="ndetailsbox">
      <!-- 左侧 -->
      <div class="ndetailsbox-left">
        <div class="ndeltitle">{{details.title}}</div>
        <div class="newsbtm">
          <div>
            <span>时间：{{details.createtime}}</span> <span style="padding: 0 20px;">|</span> <span>来自：{{details.resource}}</span>
          </div>
          <div style="cursor: pointer;position: relative;" @mousemove="funmousemove()">
            <img src="@/assets/imgae/pc/share.png" alt="">分享
            <div @mouseleave="type=''">
              <share :sysInfo="'健康科普 牙龈出血怎么办'" :type.sync="type"></share>
            </div>
          </div>
        </div>
        <p  v-html="details.content" style="padding-top: 20px;padding-bottom: 15px;font-size: 16px;color: #666;line-height: 1.8;overflow: hidden;">
        </p>
      </div>
      <!-- 右侧 -->
      <div class="ndetailsbox-right">
        <div class="remt">
          热门新闻
        </div>
        <div class="remlist" v-for="(item,index) in hotnews" :key="index" @click="funremlist(item.id)">
          <div class="remnum" :style="index==0?'background-color: #ea5406;':index==1?'background-color: #4ab998;':index==2?'background-color: #199ed8;':''">{{index+1}}</div>
          <div class="remlisttitle">{{item.title}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {newsinfo} from '@/api/pc.js'
export default {
  name: 'listdetails',
  props: ['id'],
  data() {
    return {
      activeName: '',
      type: '',
      details: {},
      mousemoveindex: '',
      hotnews: []
    };
  },
  created() {
    this.activeName = this.$route.query.activeName
    this.funnewsinfo()
  },
  computed: {},
  methods: {
    funnewsinfo() {
      let obj = {id: this.$route.query.id}
      var that = this
      newsinfo(obj).then((response) => {
        that.details = response.data
        that.hotnews = response.data.hotnews
        
        let head = document.getElementsByTagName('head');
        let meta_keyword=document.createElement('meta');
        if(document.querySelector('meta[name="keywords"]')){
          document.querySelector('meta[name="keywords"]').setAttribute('content',response.data.seo_keywords)
        }else{
          meta_keyword.setAttribute('name','keywords')
          meta_keyword.setAttribute('content',response.data.seo_keywords)
          head[0].appendChild(meta_keyword)
        }
        let meta_description=document.createElement('meta');
        if(document.querySelector('meta[name="description"]')){
          document.querySelector('meta[name="description"]').setAttribute('content',response.data.seo_description)
        }else{
          meta_description.setAttribute('name','description')
          meta_description.setAttribute('content',response.data.seo_description)
          head[0].appendChild(meta_description)
        }
        document.title = response.data.seo_title
        
      })
    },
    // 热门新闻跳转
    funremlist(id) {
      if (this.activeName == '医院新闻') {
        this.$route.query.id = id
        this.funnewsinfo()
        window.scrollTo({
           top:0,
          left:0,
          behavior: "smooth"
        })
      } else {
        this.$router.push({path: '/newsinformationdetails', query: {id: id,activeName:'医院新闻'}})
      }
    },
    funmousemove(i) {
      this.type = 2
    }
  }
}
</script>

<style scoped lang="scss">
.listdetails{
  padding: 40px 0;
  .ndetailsbox{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .ndetailsbox-left{
      width: 860px;
      .ndeltitle{
        font-size: 28px;
        color: #555;
        margin: 10px 0;
      }
      .newsbtm{
        font-size: 14px;
        color: #999;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 15px;
        img{
          width: 17px;
          height: 17px;
          vertical-align: bottom;
          margin-right: 5px;
        }
      }
    }
    .ndetailsbox-right{
      width: 280px;
      .remt{
        font-size: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #e4e4e4;
      }
      .remlist:last-child{
        border-bottom: none;
      }
      .remlist{
        height: 60px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px dashed #e4e4e4;
        cursor: pointer;
        // white-space:nowrap;
        // overflow:hidden;
        // text-overflow:ellipsis;
        .remnum{
          width: 30px;
          height: 30px;
          border-radius: 3px;
          background-color: #999;
          color: #fff;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .remlisttitle{
          width: 240px;
          margin-left: 10px;
          font-size: 14px;
          line-height: 20px;
          color: #555;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
        }
        .remlisttitle:hover{
          animation: fadeInAnimation ease 1s;
          animation-iteration-count: 1; 
          animation-fill-mode: forwards;
        }
        @keyframes fadeInAnimation {
          0% {
            color: #555;
          } 
          100% {
            color: #4ab998;
          } 
        }
      }
    }
  }
}
</style>
