<template>
  <div class="list">
    <div class="newslists" v-for="(item,index) in arr" :key="index">
      <div class="newsflexbox">
        <div class="newslt" @click="torouters(item.id)">
          <img :src="item.image" alt="">
        </div>
        <div class="newsrg">
          <div class="newtitle" @click="torouters(item.id)">{{item.title}}</div>
          <div class="details" @click="torouters(item.id)">{{item.content}}</div>
          <div class="newsbtm">
            <div>
              <span>时间：{{item.createtime}}</span> <span style="padding: 0 20px;">|</span> <span>来自：{{item.resource}}</span>
            </div>
            <div style="cursor: pointer;position: relative;" @mousemove.stop="funmousemove(index)">
              <img src="@/assets/imgae/pc/share.png" alt="">分享
              <div v-if="index == mousemoveindex" @mouseleave.stop="type=''">
                <share :sysInfo="item.title" :type.sync="type"></share>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
  props: [
    'activeName','arr','torouter'
  ],
  data() {
    return {
      type: '',
      mousemoveindex: ''
    };
  },
  computed: {},
  methods: {
    torouters(id) {
      if(this.activeName=='医院新闻'||this.activeName=='科室风采'||this.activeName=='媒体报道'){
        this.$router.push({path: '/newsinformationdetails', query: {id: id,activeName:this.activeName}})
      }
      if (this.activeName=='教学科研') {
        this.$router.push({path: '/researchdetails', query: {id: id,activeName:this.activeName}})
      }
      if (this.activeName=='口腔科普') {
        this.$router.push({path: '/healthknowledgedetails', query: {id: id,activeName:this.activeName}})
      }
    },
    funmousemove(i) {
      this.type = 2
      this.mousemoveindex = i
    }
  }
}
</script>

<style scoped lang="scss">
// 列表
.newslists{
  display: flex;
  align-items: center;
  .newsflexbox{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    border-bottom: 1px dashed #e4e4e4;
    padding: 20px 0;
  }
  .newsrg{
    width: 900px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .newtitle {
    cursor: pointer;
    font-size: 20px;
    display: block;
    max-height: 52px;
    font-weight: 700;
    color: #555;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    transition: color 0.5s linear 0s;
    -webkit-transition: color 0.5s linear 0s;
    -moz-transition: color 0.5s linear 0s;
    -o-transition: color 0.5s linear 0s;
  }
  .newtitle:hover {
    color: #4ab998;
  }
  .details{
    max-height: 60px;
    font-size: 14px;
    color: #797979;
    line-height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    padding: 0;
    margin: 18px 0 0;
    overflow: hidden;
  }
  .newsbtm{
    font-size: 14px;
    color: #999;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
      width: 17px;
      height: 17px;
      vertical-align: bottom;
      margin-right: 5px;
    }
  }
  .newslt{
    width: 230px;
    height: 184px;
    overflow: hidden;
    margin-right: 20px;
    img{
      width: 100%;
      height: 100%;
    }
    img:hover{
      transform: scale(1.2);
      transition: transform 1s ease-in-out;
    }
  }
}
</style>
