import request from '@/utils/request.js'

export const pchome = (query) => {
  return request({
    method: 'get',
    url: '/index.php/api/index/index',
    params: query
  })
}

export const pcheader = (query) => {
  return request({
    method: 'get',
    url: '/index.php/api/index/header',
    params: query
  })
}

export const pcadvice = (query) => {
  return request({
    method: 'get',
    url: '/index.php/api/index/advice',
    params: query
  })
}

export const newslist = (query) => {
  return request({
    method: 'get',
    url: '/index.php/api/index/newslist',
    params: query
  })
}

export const newsinfo = (query) => {
  return request({
    method: 'get',
    url: '/index.php/api/index/newsinfo',
    params: query
  })
}
