import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

var router = '',routes=""
// pc端
if(document.documentElement.clientWidth >= 1200) {
  routes = [
    {
      path: '/', // 共用头部
      // name: 'share',
      component: () => import('@/views/share/index'),
      children: [
        // pc端
        {
          path: '/',
          name: 'computerhome',
          component: () => import('@/views/pcpage/home/index'),
          meta: {active: 0,name: '首页'}
        },
        {
          path: '/walkintohx',
          name: 'walkintohx',
          component: () => import('@/views/pcpage/walkintohx/index'),
          meta: {active: 1,name: '走进和协'}
        },
        {
          path: '/newsinformation',
          name: 'newsinformation',
          component: () => import('@/views/pcpage/newsinformation/index'),
          meta: {active: 2,name: '新闻动态'}
        },
        {
          path: '/newsinformationdetails',
          name: 'newsinformationdetails',
          component: () => import('@/views/pcpage/newsinformationdetails/index'),
          meta: {active: 2,name: '新闻动态详情'}
        },
        {
          path: '/department',
          name: 'department',
          component: () => import('@/views/pcpage/department/index'),
          meta: {active: 3,name: '科室介绍'}
        },
        {
          path: '/introductiondetails',
          name: 'introductiondetails',
          component: () => import('@/views/pcpage/introductiondetails/index'),
          meta: {active: 3,name: '科室介绍详情'}
        },
        {
          path: '/doctorteam',
          name: 'doctorteam',
          component: () => import('@/views/pcpage/doctorteam/index'),
          meta: {active: 4,name: '医生团队'}
        },
        {
          path: '/doctordetails',
          name: 'doctordetails',
          component: () => import('@/views/pcpage/doctordetails/index'),
          meta: {active: 4,name: '医生团队详情'}
        },
        {
          path: '/research',
          name: 'research',
          component: () => import('@/views/pcpage/research/index'),
          meta: {active: 5,name: '教学科研'}
        },
        {
          path: '/researchdetails',
          name: 'researchdetails',
          component: () => import('@/views/pcpage/researchdetails/index'),
          meta: {active: 5,name: '教学科研详情'}
        },
        {
          path: '/guide',
          name: 'guide',
          component: () => import('@/views/pcpage/guide/index'),
          meta: {active: 6,name: '就医指南'}
        },
        {
          path: '/healthknowledge',
          name: 'healthknowledge',
          component: () => import('@/views/pcpage/healthknowledge/index'),
          meta: {active: 7,name: '健康知识'}
        },
        {
          path: '/healthknowledgedetails',
          name: 'healthknowledgedetails',
          component: () => import('@/views/pcpage/healthknowledgedetails/index'),
          meta: {active: 7,name: '健康知识详情'}
        },
        {
          path: '/popularizationvideo',
          name: 'popularizationvideo',
          component: () => import('@/views/pcpage/popularizationvideo/index'),
          meta: {active: 7,name: '科普视频'}
        },
        {
          path: '/route',
          name: 'route',
          component: () => import('@/views/pcpage/route/index'),
          meta: {active: 8,name: '来院路线'}
        },
        {
          path: '/recruit',
          name: 'recruit',
          component: () => import('@/views/pcpage/recruit/index'),
          meta: {active: 9,name: '诚聘英才'}
        }
      ]
    }
  ]
} else {
  routes = [
    {
      path: '/', // 共用头部
      // name: 'share',
      component: () => import('@/views/share/index'),
      children: [
        // mobile端
        {
          path: '/',
          name: 'mobilepage',
          component: () => import('@/views/mobilepage/home/index'),
          meta: {active: 0,name: '首页'}
        },
        {
          path: '/walkintohx',
          name: 'walkintohx',
          component: () => import('@/views/mobilepage/walkintohx/index'),
          meta: {active: 1,name: '走进和协'}
        },
        {
          path: '/newsinformation',
          name: 'newsinformation',
          component: () => import('@/views/mobilepage/newsinformation/index'),
          meta: {active: 2,name: '新闻动态'}
        },
        {
          path: '/department',
          name: 'department',
          component: () => import('@/views/mobilepage/department/index'),
          meta: {active: 3,name: '科室介绍'}
        },
        {
          path: '/introductiondetails',
          name: 'introductiondetails',
          component: () => import('@/views/mobilepage/introductiondetails/index'),
          meta: {active: 3,name: '科室介绍详情',show: false}
        },
        {
          path: '/doctorteam',
          name: 'doctorteam',
          component: () => import('@/views/mobilepage/doctorteam/index'),
          meta: {active: 4,name: '医生团队'}
        },
        {
          path: '/doctordetails',
          name: 'doctordetails',
          component: () => import('@/views/mobilepage/doctordetails/index'),
          meta: {active: 4,name: '医生团队详情',show: false}
        },
        {
          path: '/research',
          name: 'research',
          component: () => import('@/views/mobilepage/research/index'),
          meta: {active: 5,name: '教学科研'}
        },
        {
          path: '/researchdetails',
          name: 'researchdetails',
          component: () => import('@/views/mobilepage/researchdetails/index'),
          meta: {active: 5,name: '教学科研详情',show: false}
        },
        {
          path: '/guide',
          name: 'guide',
          component: () => import('@/views/mobilepage/guide/index'),
          meta: {active: 6,name: '就医指南'}
        },
        {
          path: '/healthknowledge',
          name: 'healthknowledge',
          component: () => import('@/views/mobilepage/healthknowledge/index'),
          meta: {active: 7,name: '健康知识'}
        },
        {
          path: '/healthknowledgedetails',
          name: 'healthknowledgedetails',
          component: () => import('@/views/mobilepage/healthknowledgedetails/index'),
          meta: {active: 7,name: '健康知识详情',show: false}
        },
        {
          path: '/popularizationvideo',
          name: 'popularizationvideo',
          component: () => import('@/views/mobilepage/popularizationvideo/index'),
          meta: {active: 7,name: '科普视频',show: false}
        },
        {
          path: '/newsinformationdetails',
          name: 'newsinformationdetails',
          component: () => import('@/views/mobilepage/newsinformationdetails/index'),
          meta: {active: 2,name: '',show: false}
        },
        {
          path: '/route',
          name: 'route',
          component: () => import('@/views/mobilepage/route/index'),
          meta: {active: 8,name: '来院路线'}
        },
        {
          path: '/recruit',
          name: 'recruit',
          component: () => import('@/views/mobilepage/recruit/index'),
          meta: {active: 9,name: '诚聘英才'}
        }
      ]
    }
  ]
}

router = new VueRouter({
  mode:"history",
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
