import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import {pcheader} from '@/api/pc.js'
Vue.prototype.$arr = []
Vue.prototype.$index = ''

// 分页组件
import Pagination from "@/components/pc/Pagination";
// list组件
import List from "@/components/pc/List";
// list详情组件
import Listdetails from "@/components/pc/Listdetails";
// 分享
import share from "@/components/pc/share";
// 视频插件
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
// 百度地图
import BaiduMap from 'vue-baidu-map'
 
Vue.use(VueDPlayer);
Vue.use(ElementUI);
Vue.use(BaiduMap, {ak:'0uf0YlUHOqmqUgTbRGiIqDIoDj0NUMy8'})

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('List', List)
Vue.component('Listdetails', Listdetails)
Vue.component('share', share)

Vue.config.productionTip = false

router.beforeEach((to,from,next)=>{
  if (to.fullPath=='/index.html') {
    next('/')
  }
  if (to.matched.length === 0) {
    next('/')
  }
  var that = this,obj={}
  pcheader(obj).then((response) => {
    for (var i = 0; i < response.data.navlist.length; i++) {
      if (to.meta.active == response.data.navlist[i].id-1) {
        let head = document.getElementsByTagName('head');
        let meta_keyword=document.createElement('meta');
        if(document.querySelector('meta[name="keywords"]')){
          document.querySelector('meta[name="keywords"]').setAttribute('content',response.data.navlist[i].seo_keywords)
        }else{
          meta_keyword.setAttribute('name','keywords')
          meta_keyword.setAttribute('content',response.data.navlist[i].seo_keywords)
          head[0].appendChild(meta_keyword)
        }
        let meta_description=document.createElement('meta');
        if(document.querySelector('meta[name="description"]')){
          document.querySelector('meta[name="description"]').setAttribute('content',response.data.navlist[i].seo_description)
        }else{
          meta_description.setAttribute('name','description')
          meta_description.setAttribute('content',response.data.navlist[i].seo_description)
          head[0].appendChild(meta_description)
        }
        document.title = response.data.navlist[i].seo_title
      }
    }
  })
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
