import axios from 'axios'
// 创建axios实例

const request = axios.create({
  // 请求的公共接口地址
  // baseURL: '/api',
  // baseURL: 'http://ceshi.bjyrwh.com',
  
  
  baseURL: 'http://www.hexieyiyuan.com',
  // 请求的超时时间
  timeout: 10000
})

// 配置请求拦截和响应拦截
// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers = { 'Content-Type': 'multipart/form-data' }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})

// 导出axios实例
export default request
