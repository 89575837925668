<template>
  <div class="shareBox">
    <!-- 视频分享 -->
    <template v-if="type==1">
      分享到：
      <span class="hover-pointer" @click="shareToWeChat()">
        <img src="@/assets/imgae/pc/wx.png" alt="" title="分享到微信"/>
      </span>
      <span class="hover-pointer" @click="shareToMicroblog()">
        <img src="@/assets/imgae/pc/wb.png" title="分享到新浪微博" style="margin: 0 5px;" alt="" />
      </span>
      <span class="hover-pointer" @click="shareToQQRom()">
        <img src="@/assets/imgae/pc/qq.png" title="分享到QQ空间" alt="" />
      </span>
    </template>
    <!-- 列表&&详情分享 -->
    <template v-if="type==2&&ewm!=true">
      <div class="common-list-share share" style="background-color: white;">
        <div class="title">分享到</div>
        <div class="bdshare">
          <span class="bds_weixin" data-cmd="weixin" @click="shareToWeChat()">
            <img src="@/assets/imgae/pc/wx.png" alt="" title="分享到微信" style="margin: 0 5px;"/>
            <span>微信</span>
          </span>
          <span class="bds_tsina" data-cmd="tsina" @click="shareToMicroblog()">
            <img src="@/assets/imgae/pc/wb.png" alt=""  title="分享到新浪微博" style="margin: 0 5px;"/>
            <span>微博</span>
          </span>
          <span class="bds_qzone" data-cmd="qzone" @click="shareToQQRom()">
            <img src="@/assets/imgae/pc/qq.png" alt="" title="分享到QQ空间" style="margin: 0 5px;"/>
            <span>QQ空间</span>
          </span>
        </div>
      </div>
    </template>
    <!-- 微信二维码 -->
    <div v-show="ewm" id="foxWechatBox" style="display: block; background: #fff; left: 50%; top: 50%; margin-left: -90px; margin-top: -122.5px; position: fixed; height: 245px; width: 180px;border:1px solid #d8d8d8;z-index: 10000;padding: 15px;">
      <div style="line-height: 16px;position: relative;color: #000;">
        <span>分享到微信</span>
        <div @click="gb()" class="share_weixin_close" style="width: 16px;height: 16px;text-align: center;font-size: 16px;position: absolute;top: 0; right: 0;">×</div>
      </div>
      <div class="share_weixin_qrcode" style="min-height: 180px;margin:15px 0">
        <canvas id="QRCode_header" style="width: 180px; height: 180px"></canvas>
      </div>
      <p style="text-align: center;">打开微信扫描二维码</p>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  props: ["sysInfo","type"],  //父组件传过来的文章数据
  data() {
    return {
      shareUrl: location.href,
      ewm: false
    };
  },
  methods: {
    /**
     * 分享到微博
     */
    shareToMicroblog() {
      //跳转地址
      window.open(
        "https://service.weibo.com/share/share.php?url=" +
          encodeURIComponent(this.shareUrl) +
          "&title=" +
          this.sysInfo
      );
    },
    /**
     * 分享到qq空间
     */
    shareToQQRom() {
      //跳转地址
      window.open(
        "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
        encodeURIComponent(this.shareUrl) +
        "&title=" +
        this.sysInfo +
        "&summary=" +
        this.sysInfo
      );
    },
    /**
     * 分享到微信：生成二维码
     */
    shareToWeChat() {
      this.getQRCode()
    },
    getQRCode() {  
    //生成的二维码为URL地址js
    var that = this
    this.qrUrl= this.shareUrl;
      let opts = {
        errorCorrectionLevel: "H", //容错级别
        type: "image/png", //生成的二维码类型
        quality: 0.3, //二维码质量
        margin: 0, //二维码留白边距
        width: 180, //宽
        height: 180, //高
        text: "", //二维码内容
        color: {
          dark: "#333333", //前景色
          light: "#fff", //背景色
        },
      };
      
      let msg = document.getElementById("QRCode_header");
      // 将获取到的数据（val）画到msg（canvas）上
      QRCode.toCanvas(msg, this.qrUrl, opts, function (error) {
        that.ewm = true
        if (error) {
          console.log("二维码加载失败", error);
          this.$message.error("二维码加载失败");
        }
      });
    },
    gb() {
      this.ewm = false
    }
  },
};
</script>

<style lang="scss" scoped>
.shareBox {
  .iconfont {
    font-size: 22px !important;
  }
  img{
    width: 17px;
    height: 17px;
    vertical-align: bottom;
  }
  .common-list-share {
    position: absolute;
    width: 75px;
    left: -30px;
    bottom: 25px;
    border: 1px solid #e4e4e4;
  }
  .common-list-share .title {
    font-size: 12px;
    color: #333;
    line-height: 20px;
    padding-left: 5px;
    box-sizing: border-box;
    margin-bottom: 5px;
    border-bottom: 1px solid #e4e4e4;
  }
  .bdshare span{
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .common-list-share span {
    line-height: 35px;
    font-size: 12px;
  }
  .common-list-share span:hover span {
    color: #4ab998;
  }
}
</style>